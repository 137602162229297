import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);

  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);

  // Get hourly price from `price`
  const hourlyPrice = price;
  // Get daily price from `publicData`
  const dailyPriceAmount = publicData?.pricePerDayAmount
  const dailyPriceCurrency = publicData?.pricePerDayCurrency;

  // Check if prices are set
  const hasHourlyPrice = !!hourlyPrice;
  const hasDailyPrice = !!dailyPriceAmount && !!dailyPriceCurrency;

  // If neither price is set, return null or display a message
  if (!hasHourlyPrice && !hasDailyPrice) {
    return null;
  }

  // Create Money instance for daily price
  let dailyPrice;
  if (hasDailyPrice) {
    const dailyPriceSubunits = dailyPriceAmount;
    dailyPrice = new Money(dailyPriceSubunits, dailyPriceCurrency);
  }

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  return (
    <div className={css.price}>
      <div className={css.priceValue}>
        {/* Display hourly price if set */}
        {hasHourlyPrice && (
          <span className={css.priceItem}>
            {formatMoney(intl, hourlyPrice)}
            {isBookable ? (
              <span className={css.perUnit}>
                <FormattedMessage id="ListingCard.perUnit" values={{ unitType: 'hour' }} />
              </span>
            ) : null}
          </span>
        )}
        {hasHourlyPrice && hasDailyPrice && (
          <span className={css.priceDivider}> / </span>
        )}
        {/* Display daily price if set */}
        {hasDailyPrice && (
          <span className={css.priceItem}>
            {formatMoney(intl, dailyPrice)}
            {isBookable ? (
              <span className={css.perUnit}>
                <FormattedMessage id="ListingCard.perUnit" values={{ unitType: 'day' }} />
              </span>
            ) : null}
          </span>
        )}
      </div>
    </div>
  );
};
export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData} = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const clinicType = publicData.clinic_type ? publicData.clinic_type.split("_")[0] : '';
  const spaceType = publicData.space_type ? publicData.space_type.split("_")[0] : '';
  const address = publicData && publicData.location ? publicData.location.address : '';
  const address2 = address ? address.split(",") : [];
  const city = address2[2] || '';
  const town = address2[1] || '';

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {clinicType} {spaceType} Space in {town}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);